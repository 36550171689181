import React, {FC, useEffect, useRef, useState} from "react";
import * as firebaseui from "firebaseui";
import firebase from 'firebase/compat/app';
import {onAuthStateChanged} from "firebase/auth";

interface FirebaseAuthenticatorProps{
  redirectUrl?: string
}

export const FirebaseAuthenticator : FC<FirebaseAuthenticatorProps> = ({redirectUrl}) => {

  const [isSignedIn, setIsSignedIn] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const uiConfig: firebaseui.auth.Config = {
      signInFlow: 'popup',
      signInSuccessUrl: redirectUrl,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
        // Miniclip Azure OAuth authentication
        // {
        //   provider: 'microsoft.com',
        //   customParameters: {
        //     tenant: "7518160b-ea96-4014-9f40-0ed39bbab70a"
        //   }
        // },
        //  Uncomment for email provider:  firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
    };

    const unregisterAuthObserver = onAuthStateChanged(firebase.auth(), (user) => {
      setIsSignedIn(!!user);
    });

    const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

    if(!isSignedIn)
      elementRef.current && firebaseUiWidget.start(elementRef.current, uiConfig);
    else
      firebaseUiWidget.reset();

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [redirectUrl, isSignedIn, setIsSignedIn]);

  return <div ref={elementRef}/>
}
